import { useCallback, useEffect, useState } from 'react'

type Callback = () => void

export function useInterval(f: Callback, delay: number) {
  const [timer, setTimer] = useState<NodeJS.Timeout | void | null>(null)

  const start = useCallback(() => {
    if (timer) return
    setTimer(setInterval(f, delay))
  }, [timer, f, delay])

  const stop = useCallback(() => {
    if (!timer) return
    setTimer(clearInterval(timer))
  }, [timer])

  useEffect(() => stop, [stop])

  return [start, stop, timer != null] as const
}
