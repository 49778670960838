import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { CssBaseline, makeStyles } from '@material-ui/core'
import React from 'react'
import { AppLoaderTopBar } from 'common/components/loading/AppLoaderBar'
import { LoadingProvider } from 'common/context/LoadingContext'

const useStyles = makeStyles(() => ({
  snackbarRoot: {
    marginTop: '8px',
  },
}))

const AppProviders: React.FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Router>
      <LoadingProvider>
        <CssBaseline>
          <AppLoaderTopBar>
            <SnackbarProvider classes={{ root: classes.snackbarRoot }}>{children}</SnackbarProvider>
          </AppLoaderTopBar>
        </CssBaseline>
      </LoadingProvider>
    </Router>
  )
}

export default AppProviders
