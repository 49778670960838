import { FallbackProps } from 'react-error-boundary'
import { Button, Box, Container } from '@material-ui/core'

const FallbackComponent = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <Container maxWidth="md">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <h1 style={{ color: 'red' }}>Something went wrong</h1>
        <Button variant="contained" color="primary" onClick={resetErrorBoundary}>
          Restore
        </Button>
      </Box>
      <h2>{error.name}</h2>
      <pre>{error.message}</pre>
      <br></br>
      <pre>{error.stack}</pre>
    </Container>
  )
}

export default FallbackComponent
