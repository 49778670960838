import React, { ReactElement, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { GLOBAL_FILE_HANDLER_API, GLOBAL_API } from 'config'
import FullPageLoading from 'common/components/loading/FullPageLoading'

const GlobalComponent = React.lazy(() => import('./Global'))
const CompanyManagerComponent = React.lazy(() => import('./CompanyManager'))

function throwMissingEnvError(type: string) {
  throw new Error(`Missing ${type}. Please provide one as an env variable`)
}

function App(): ReactElement {
  if (!GLOBAL_API) throwMissingEnvError('graphql global api url')
  if (!GLOBAL_FILE_HANDLER_API) throwMissingEnvError('file handler api url')

  return (
    <Suspense fallback={FullPageLoading}>
      <SanitizeRoutes>
        <Switch>
          <Route path={'/manage-company/:uuid/'}>
            <CompanyManagerComponent />
          </Route>
          <Route path={'/'}>
            <GlobalComponent />
          </Route>
        </Switch>
      </SanitizeRoutes>
    </Suspense>
  )
}

/**
 * When routes does not have trailing "/", it automatically places one, to avoid bugs
 */
const SanitizeRoutes: React.FC = ({ children }) => {
  return (
    <Route
      path="*"
      render={(props) => {
        const url = props.match.url
        const regex = /\/\/+/g
        if (url.match(regex)) {
          const cleanUrl = url.replace(regex, '/')
          return <Redirect to={cleanUrl} />
        } else {
          return children
        }
      }}
    />
  )
}

export default App
