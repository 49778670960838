import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import dayjs from 'dayjs'

void i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('lang') ?? 'en',
    fallbackLng: 'en',
    debug: true,
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
      format: (value: unknown, format) => {
        if (typeof value === 'string') {
          if (format === 'uppercase') return value.toUpperCase()
          if (format === 'capitalize') return value.replace(/^\w/, (c) => c.toUpperCase())
          if (format === 'lowercase') return value.toLowerCase()
        }
        if (typeof value === 'number') return String(value)
        if (value instanceof Date) return dayjs(value).format(format)
        return ''
      },
    },
  })

export default i18n
